import React, { useState } from "react";
import '../../styles/pages/dashboard-inner/NotificationsCenter.css';
import { format } from 'date-fns';
import useAuth from '../../hooks/useAuth';

function NotificationsCenter() {
    const { csrfToken, user } = useAuth();

    const apiUrl = process.env.REACT_APP_DEV_OR_PROD === 'dev' ? process.env.REACT_APP_DEV_FRONT : process.env.REACT_APP_PROD_FRONT;

    // Assuming user.notifications is already in the format you provided:
    // [{id: '66ca2e94001bbba317c0', message: 'Payment Successful', type: 1, timestamp: 1724526223, seen: false}, ...]
    const [notifications, setNotifications] = useState(
        user.notifications.map(notification => ({
            id: notification.id,
            message: notification.message,
            type: notification.type === 1 ? 'success' :
                notification.type === 2 ? 'warning' :
                    notification.type === 3 ? 'error' :
                        'info', // Mapping the type to strings like 'success', 'warning', 'error', 'info'
            date: new Date(notification.timestamp * 1000), // Convert Unix timestamp to JavaScript Date object
            read: notification.seen
        }))
    );

    const markAsRead = async (id) => {
        try {
            const response = await fetch(`${apiUrl}/server/notification-read`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'CSRF-Token': csrfToken,  // Including the CSRF token in the headers
                },
                body: JSON.stringify({
                    notificationId: id,
                    modoId: user.id // Include the user's modoId in the request body
                }),
                credentials: 'include',  // Include credentials in the request
            });

            if (response.ok) {
                setNotifications(notifications.map(notification =>
                    notification.id === id ? { ...notification, read: true } : notification
                ));
            } else {
                console.error('Failed to mark notification as read');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const markAllAsRead = async () => {
        try {
            const unreadNotificationIds = notifications.filter(notification => !notification.read).map(notification => notification.id);

            if (unreadNotificationIds.length > 0) {
                const response = await fetch(`${apiUrl}/server/notification-read`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'CSRF-Token': csrfToken,  // Including the CSRF token in the headers
                    },
                    body: JSON.stringify({
                        ids: unreadNotificationIds,
                        modoId: user.id // Include the user's modoId in the request body
                    }),
                    credentials: 'include',  // Include credentials in the request
                });

                if (response.ok) {
                    setNotifications(notifications.map(notification => ({
                        ...notification,
                        read: true
                    })));
                } else {
                    console.error('Failed to mark all notifications as read');
                }
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const formatDate = (date) => format(date, 'dd/MM/yyyy HH:mm');

    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);
    const lastWeek = new Date(today);
    lastWeek.setDate(today.getDate() - 7);
    const lastMonth = new Date(today);
    lastMonth.setMonth(today.getMonth() - 1);

    const categorizeNotifications = () => {
        const categories = {
            'Today': [],
            'Yesterday': [],
            'Last Week': [],
            'Last Month': [],
            'All Time': [],
        };

        notifications.forEach(notification => {
            if (notification.date.toDateString() === today.toDateString()) {
                categories['Today'].push(notification);
            } else if (notification.date.toDateString() === yesterday.toDateString()) {
                categories['Yesterday'].push(notification);
            } else if (notification.date > lastWeek) {
                categories['Last Week'].push(notification);
            } else if (notification.date > lastMonth) {
                categories['Last Month'].push(notification);
            } else {
                categories['All Time'].push(notification);
            }
        });

        return categories;
    };

    const categorizedNotifications = categorizeNotifications();

    return (
        <div className='expanded-container notifications-portal show'>
            <div className="notifications-center">
                <h2>Notifications Center</h2>
                <div className="notifications-list">
                    {Object.keys(categorizedNotifications).map(category => (
                        categorizedNotifications[category].length > 0 && (
                            <div key={category} className="notification-category">
                                <h3>{category}</h3>
                                {categorizedNotifications[category].map(notification => (
                                    <div key={notification.id} className={`notification-item ${notification.read ? 'read' : 'unread'} ${notification.type}`}>
                                        <div className="notification-message">{notification.message}</div>
                                        <div className="notification-date">{formatDate(notification.date)}</div>
                                        <button className="mark-read-button" onClick={() => markAsRead(notification.id)}>X</button>
                                    </div>
                                ))}
                            </div>
                        )
                    ))}
                    {notifications.length === 0 && <p>No notifications available.</p>}
                </div>
            </div>
            <button className="mark-all-read-button" onClick={markAllAsRead}>
                Mark All as Read
            </button>
        </div>
    );
}

export default NotificationsCenter;
