import React, { useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleLeft, faVideo, faBook, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import '../styles/pages/Docs.css';

function Docs() {
    const [selectedCard, setSelectedCard] = useState(null);

    const handleCardClick = (card) => {
        setSelectedCard(card);
    };

    const handleBackClick = () => {
        setSelectedCard(null);
    };

    const videoData = {
        "Getting Started": {
            src: "https://www.example.com/video1.mp4",
            title: "Getting Started"
        },
        "Advanced Features": {
            src: "https://www.example.com/video2.mp4",
            title: "Advanced Features"
        },
        "Tips & Tricks": {
            src: "https://www.example.com/video3.mp4",
            title: "Tips & Tricks"
        }
    };

    return (
        <div className="docs-page">
            {selectedCard ? (
                <div className="docs-container">
                    <button onClick={handleBackClick} className="back-button">Back</button>
                    <div className="selected-card-content">
                        <h1>{videoData[selectedCard].title} Video</h1>
                        <div className="docs-area-container">
                            <video
                                className="d-block w-100"
                                controls
                                src={videoData[selectedCard].src}
                                alt={`${videoData[selectedCard].title} video`}
                            />
                        </div>
                    </div>
                </div>
            ) : (
                <div className='primary-docs-container'>
                    <h1>Documentation Videos</h1>
                    <p className='subtitle-docs'>Watch our instructional videos to learn how to use our documentation effectively.</p>
                    <div className="docs-cards">
                        <div className="docs-card" onClick={() => handleCardClick('Getting Started')}>
                            <div className="docs-card-icon-wrapper">
                                <FontAwesomeIcon icon={faVideo} className="docs-card-icon" />
                            </div>
                            <h2>Getting Started</h2>
                            <p>Watch our introductory video.</p>
                            <div className='docs-child'>
                                <button className='nav-button-docs'>Watch Now</button>
                            </div>
                        </div>
                        <div className="docs-card" onClick={() => handleCardClick('Advanced Features')}>
                            <div className="docs-card-icon-wrapper">
                                <FontAwesomeIcon icon={faBook} className="docs-card-icon" />
                            </div>
                            <h2>Advanced Features</h2>
                            <p>Explore advanced features in this video.</p>
                            <div className='docs-child'>
                                <button className='nav-button-docs'>Watch Now</button>
                            </div>
                        </div>
                        <div className="docs-card" onClick={() => handleCardClick('Tips & Tricks')}>
                            <div className="docs-card-icon-wrapper">
                                <FontAwesomeIcon icon={faInfoCircle} className="docs-card-icon" />
                            </div>
                            <h2>Tips & Tricks</h2>
                            <p>Learn tips and tricks in this video.</p>
                            <div className='docs-child'>
                                <button className='nav-button-docs'>Watch Now</button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default Docs;
