import React, { useState, useEffect } from "react";
import '../styles/pages/DashboardReplacement.css';
import DownloadSoftware from "./dashboard-inner/DownloadSoftware";
import useAuth from "../hooks/useAuth";
import useRefreshUser from "../hooks/useRefreshUser";
import PersonalDetails from "./dashboard-inner/PersonalDetails";
import PaymentDetails from "./dashboard-inner/PaymentDetails";
import PlanDetails from "./dashboard-inner/PlanDetails";
import SettingsConfiguration from "./dashboard-inner/SettingsConfiguration";
import NotificationsCenter from "./dashboard-inner/NotificationsCenter";

function DashboardReplacement() {
    const [clickedItem, setClickedItem] = useState(null);
    const [fadeComplete, setFadeComplete] = useState(false);
    const [showExpanded, setShowExpanded] = useState(false);
    const [fadeOutExpanded, setFadeOutExpanded] = useState(false);
    const [backButtonClass, setBackButtonClass] = useState(''); // New state for back button class

    const apiUrl = process.env.REACT_APP_DEV_OR_PROD === 'dev' ? process.env.REACT_APP_DEV_FRONT : process.env.REACT_APP_PROD_FRONT;

    useRefreshUser();

    const { user } = useAuth();

    const items = [
        { id: 1, icon: "/assets/icons/subscription-model.png", title: "My Plan", component: PlanDetails, backButtonClass: 'back-button-plan' },
        { id: 2, icon: "/assets/icons/credit-card.png", title: "My Payment Details", component: PaymentDetails, backButtonClass: 'back-button-payment-details' },
        { id: 3, icon: "/assets/icons/form.png", title: "My Personal Details", component: PersonalDetails, backButtonClass: 'back-button-personal-details' },
        { id: 4, icon: "/assets/icons/notification.png", title: "Notifications Center", component: NotificationsCenter, backButtonClass: 'back-button-notification' },
        { id: 5, icon: "/assets/icons/download.png", title: "Download Software", component: DownloadSoftware, props: { user }, backButtonClass: 'back-button-app5' },
        { id: 6, icon: "/assets/icons/settings.png", title: "Account Settings", component: SettingsConfiguration, backButtonClass: 'back-button-settings' },
    ];

    useEffect(() => {
        if (clickedItem !== null) {
            setTimeout(() => setFadeComplete(true), 800);
            setTimeout(() => setShowExpanded(true), 1000);
        }
    }, [clickedItem]);

    const handleItemClick = (id) => {
        const selectedItem = items.find(item => item.id === id);
        setClickedItem(id);
        setBackButtonClass(selectedItem.backButtonClass); // Set the back button class based on the selected item
        setFadeComplete(false);
        setShowExpanded(false);
    };

    const handleBackClick = () => {
        setFadeOutExpanded(true);
        setTimeout(() => {
            setFadeOutExpanded(false);
            setShowExpanded(false);
            setFadeComplete(false);
            setClickedItem(null);
        }, 500);
    };

    const ExpandedComponent = clickedItem && showExpanded ? items.find(item => item.id === clickedItem).component : null;
    const expandedProps = clickedItem && showExpanded ? items.find(item => item.id === clickedItem).props : {};

    return (
        <div className="dashboard-wrapper">
            <div className={`dashboard-container ${fadeComplete ? 'fade-complete' : ''} ${!fadeComplete && clickedItem === null ? 'fade-in' : ''}`}>
                {items.map(item => (
                    <div
                        key={item.id}
                        className={`dashboard-item ${clickedItem ? 'fade-out' : ''}`}
                        onClick={() => handleItemClick(item.id)}
                    >
                        <div className="icon-section">
                            <img src={item.icon} alt={item.title} className="item-icon" />
                        </div>
                        <div className="title-section">
                            <div className="item-title">{item.title}</div>
                        </div>
                    </div>
                ))}
            </div>
            {ExpandedComponent && (
                <div className={`expanded-wrapper ${fadeOutExpanded ? 'fade-out-expanded' : ''}`}>
                    <button className={`back-button ${backButtonClass}`} onClick={handleBackClick}>
                        <i className="fas fa-arrow-left"></i>
                    </button>
                    <ExpandedComponent {...expandedProps} />
                </div>
            )}
        </div>
    );
}

export default DashboardReplacement;
